<template>
  <div class="rate-card">
    <div class="rate-card__circle" :style="sircleColor"></div>
    <div>
      <h4>{{ rate.title }}</h4>
      <h2>{{ rate.cost }} RUB</h2>
      <ul>
        <li v-for="(item, index) in rate.services" :key="index">- {{item}}</li>
      </ul>
    </div>
    <div class="rate-card__btn">
      <BaseButton outline @click.native="$root.$emit('triggerModal')">Заказать</BaseButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RateCard',
  props: {
    rate: {
      type: Object,
      required: true
    },
    direction: {
        type: String,
        default: 'marketing'
      }
  },
  computed: {
    sircleColor() {
        return {
        'background':  `var(--${this.direction + '-grad'})`
        }
      }
  }
}
</script>

<style scoped>
.rate-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px 30px 40px;
  border: 1px solid var(--light-black);
  box-sizing: border-box;
  overflow: hidden;
}
.rate-card__circle {
  position: absolute;
  width: 29.563em;
  height: 29.563em;
  top: -50%;
  left: 0;
  right: 0;
  background: var(--marketing-grad);
  opacity: 0.5;
  z-index: -2;
}
.rate-card:nth-child(even) .rate-card__circle {
  opacity: 0.5;
}
h4 {
  text-align: center;
  color: var(--light-black);
  margin-bottom: var(--lg);
}
h2 {
  text-align: center;
  color: var(--black);
  margin-bottom: 40px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
ul {
  list-style-type: none;
  margin-bottom: 30px;
  text-align: left !important;
  padding-left: 0;
}
ul li {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 140%;
  color: var(--light-black);
  margin-bottom: var(--md);
}
.rate-card__btn {
  align-self: center;
}
.rate-card__btn .base__btn {
  font-family: Raleway, sans-serif;
  font-weight: 600;
}
@media (max-width: 1025px) {
  h2, h4 {
    text-align: left;
  }
  ul {
    margin-bottom: 50px;
  }
  .rate-card__btn {
    align-self: start;
  }
  .rate-card:nth-child(even) .rate-card__circle {
    opacity: 0.5;
  }
}
@media (max-width: 560px) {
  h2, h4 {
    text-align: center;
  }
  .rate-card__btn {
    align-self: center;
  }
}
</style>
