<template>
  <div class="service" v-if="serv">

    <div class="service-description">
      <div class="service-description__circle" :style="sircleColor"></div>
      <div class="service-description__image">
        <img :src="serviceImage">
      </div>
      <div class="service-description__content">
        <h2>{{ serv.description.title }}</h2>
        <div>
          <p>{{ serv.description.description.first_paragraph }}</p>
          <p>{{ serv.description.description.second_paragraph }}</p>
        </div>
        <h4>{{ serv.description.start_price }} RUB</h4>
        <h5>{{ serv.description.tariffs }}</h5>
      </div>
    </div>

    <div class="service-important">
      <div class="service-important__circle" :style="sircleColor"></div>
      <div class="service-important__content">
        <img class="service-important__icon" src="../assets/services/important.svg" />
        <h4>{{ serv.important.title }}</h4>
        <div class="content__paragraphes">
          <p>{{ serv.important.first_paragraph }}</p>
          <p v-if="serv.important.second_paragraph">{{ serv.important.second_paragraph }}</p>
        </div>
      </div>
    </div>

    <div class="service-content">
      <ContentCard v-for="card in serv.content" :key="card.id" :card="card" :service="service" :direction="direction" />
    </div>

    <div class="service-rates">
      <RateCard v-for="(rate, index) in serv.rates" :key="index" :rate="rate" :direction="direction"/>
    </div>

  <!--    <div class="service-blog">
      <h3>Bonch.dev пишет</h3>
      <div class="service-blog__list">
        <BlogItem v-for="(article, index) in articles" :key="index" v-bind="article"/>
      </div>
    </div>-->
  </div>
</template>

<script>
  import ContentCard from '@/components/Service/ContentCard'
  import RateCard from '@/components/Service/RateCard'
  import servicesContent from '@/content/services.js'
  // import BlogItem from "@/components/Blog/BlogItem";

  export default {
    name: 'Service',
    props: {
      service: {
        type: String,
        default: 'seo'
      },
      direction: {
        type: String,
        default: 'marketing'
      }
    },
    components: {
      // BlogItem,
      RateCard,
      ContentCard
    },
    data () {
      return {
        articles: [
          {
            id: 1,
            image: '',
            type: { name: 'Маркетинг', value: 'marketing'},
            title: 'Название статьи оно наверное длинное получится',
            text: 'Мы вынуждены отталкиваться от того, что постоянное пропагандистское...',
            date: '12.04.2014'
          },
          {
            id: 2,
            image: '',
            type: { name: 'Маркетинг', value: 'marketing'},
            title: 'Название статьи оно наверное длинное получится',
            text: 'Мы вынуждены отталкиваться от того, что постоянное пропагандистское...',
            date: '12.04.2014'
          }
        ],
      }
    },
    computed: {
      serv () {
        return servicesContent[this.service]
      },
      serviceImage() {
        return require(`../assets/services/${this.serv.description.title}.png`)
      },
      sircleColor() {
        return {
        'background':  `var(--${this.direction + '-grad'})`
        }
      }
    }
  }
</script>

<style scoped>
.service {
  max-width: 81.875em;
  padding: 0 20px;
  margin: 0 auto;
}
.service-description {
  position: relative;
  display: flex;
  margin-top: 4.875em;
  padding: 0 4em;
  margin-bottom: 14.375em;
}
.service-description__circle {
  position: absolute;
  width: 30.875em;
  height: 30.875em;
  top: -30px;
  left: -120px;
  opacity: 0.6;
  z-index: -1;
}
.service-description__image {
  width: 24.063em;
  height: 20.750em;
  margin-right: 100px;
  border: 3px solid var(--black);
  padding: 10px;
  box-sizing: border-box;
}
.service-description__image img {
  object-fit: contain;
  height: 100%;
}
.service-description__content p, .service-important__content p {
  margin-top: 20px;
  color: #5A5A5A;
  font-size: 1.125rem;
  line-height: 140%;
}
.service-description__content h4 {
  margin: 40px 0 10px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.service-description__content h5 {
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.service-important {
  max-width: 58em;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto 14.375em;
}
.service-important__content {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
}
.service-important__icon {
  grid-row: span 2;
  width: 13.125em;
  height: 13.125em;
  margin-right: 30px;
}
.service-important__circle {
  position: absolute;
  width: 20.813em;
  height: 20.813em;
  top: -60px;
  left: 50px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(207, 230, 64, 0.8) 0%, rgba(73, 255, 124, 0.2) 70%, rgba(73, 255, 124, 0) 100%);
  opacity: 0.6;
  z-index: -1;
}
.service-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(224px, auto));
  column-gap: 35px;
  row-gap: 30px;
  justify-content: center;
  margin: 0 1.25rem 14.375em;
}

.service-rates {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  align-items: stretch;
  margin-bottom: 14.375em;
  justify-content: center;
}
.service-blog h3 {
  margin-bottom: 40px;
}
.service-blog__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.875em 3.125em;
  margin-bottom: 14.375em;
}
@media (max-width: 1025px) {
  .service-description__image {
    margin-right: 40px;
  }
  .service-content {
    column-gap: 16px;
    row-gap: 16px;
    margin-bottom: 3.750em;
  }
  .service-rates {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    margin-bottom: 3.750em;
  }
  .service-blog__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fill, auto);
    gap: 1.875em 3.125em;
    margin-bottom: 14.375em;
  }
}
@media (max-width: 769px) {
  .service {
    padding: 0 40px;
  }
  .service-description {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: 3.750em;
    margin-right: 20px;
    padding: 0;
    margin-bottom :5.313em;
  }
  .service-description__image {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin-right: 0;
  }
  .service-description__image img {
    padding: 0;
    width: 100%;
    height: 100%;
  }
  .service-content {
    column-gap: 8px;
    row-gap: 8px;
  }
  .service-important {
    margin-bottom :5.313em;
  }
}
@media (max-width: 560px) {
  h2 {
    font-size: 1.625rem;
  }
  h4 {
    font-size: 1.25rem;
  }
  p {
    font-size: 0.875rem;
  }
  .service-important__content {
    grid-template-columns: 1.438em auto;
    column-gap: 8px;
    align-items: center;
  }
  .service-important__content .content__paragraphes {
    grid-column: span 2;
  }
  .service-important__icon {
    grid-row: span 1;
    width: 1.438em;
    height: 1.438em;
  }
  .service-content {
    grid-template-columns: repeat(auto-fit, minmax(224px, 100%));
    gap: 15px;
    margin: 0 0 3.750em;
  }
  .service-important__circle {
    left: -140px;
  }
}
</style>
