<template>
  <div :class="[ this.card.id === 0 ? 'main-card' : 'content-card' ]" :style="mainCardStyles">
    <h5 v-html="card.title"></h5>
    <img :src="imgIcon"/>
  </div>
</template>

<script>
const mainCardColors = {
  'marketing': 'seo',
  'development': 'front',
  'design': 'design-dev'
}
export default {
  name: 'ContentCard',
  props: {
    card: {
      type: Object,
      required: true
    },
    service: {
      type: String,
      required: true
    },
    direction: {
        type: String,
        default: 'marketing'
      }
  },
  computed: {
    imgIcon () {
      if (this.card.id === 0) {
        return require(`../../assets/services/${this.service}/index.svg`)
      } else {
        return require(`../../assets/services/${this.service}/icon${this.card.id}.svg`)
      }
    },
    mainCardStyles() {
      if (this.card.id !== 0) return
      return {
        'background-color':  `var(--${mainCardColors[this.direction]})`
      }
    }
  }
}
</script>

<style scoped>
.content-card, .main-card {
  position: relative;
  width: 100%;
  height: 9.375em;
  padding: 20px;
  box-sizing: border-box;
}
.main-card h5 {
  position: relative;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 115%;
  color: white;
  z-index: 3;
}
.content-card {
  border: 1px solid var(--black);
}
img {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
