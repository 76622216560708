export default {
  seo: {

    // --------------- Перый блок -------------------
    description: {
      title: 'SEO', // точно так же назовите файл картинки, которая находится в самом верху страницы (в данном случае картинка '/assets/services/SEO.png')
      description: {
        first_paragraph: `Продвижение сайта представляет собой комплекс действий и мероприятий, которые направлены на постоянный приток новых посетителей за счёт высокой позиции ресурса в поисковой выдаче систем Яндекс и Google.`,
        second_paragraph: `За счёт грамотной SEO-оптимизации, поисковики приведут на ваш сайт посетителей из числа целевой аудитории, то есть тех, чьим запросам в сети соответствует направление бизнеса продвигаемой компании.`
      },
      start_price: 'от 25 000',
      tariffs: '3 тарифных плана'
    },


    // --------------- Второй блок -------------------

    important: {
      title: 'Почему SEO важно?',
      first_paragraph: `Органический трафик достается компании бесплатно, при достижение хороших результатов они будут сохранятся даже после окончания работ. Органический трафик наиболее конверсируемый (хз есть ли такое слово) и имеет лучшеи поведенческие факторы. Клиенты которые нашли вас в органической выдачи являются более лояльными так как ваш сайт выбор Яндекса и Googla в это отрасли и по этому запросу.`,
      second_paragraph: ``
    },

    // --------------- Третий блок -------------------

    // Картинки добавляем в папку:  /assets/services/[имя страницы, в данном случае "seo"]/['icon' + id].svg
    //напишу пару примеров снизу
    content: [
      {
        id: 0,
        title: 'Что входит в SEO?'
        // самую первую картинку называем index.svg
        // /assets/services/seo/index.svg
      },
      {
        id: 1,
        title: 'Оптимизация HTML-кода'
        // /assets/services/seo/icon1.svg
      },
      {
        id: 2,
        title: 'Аналитика'
        // /assets/services/seo/icon2.svg
      },
      {
        id: 3,
        title: 'Работы по улучшению сайта'
        // /assets/services/seo/icon3.svg
        // и тд...
      },
      {
        id: 4,
        title: 'Подготовка отчетов по видимости сайта<br> в поисковых<br> системах'
      },
      {
        id: 5,
        title: 'Анализ сайтов конкурентов'
      },
      {
        id: 6,
        title: 'Внутреннее продвижение'
      },
      {
        id: 7,
        title: 'Внешнее продвижение'
      },
      {
        id: 8,
        title: 'Оптимизация коммерческих факторов'
      },
      {
        id: 9,
        title: 'Оптимизация поведенческих факторов'
      },
      {
        id: 10,
        title: 'Работа с контентом сайта'
      }
    ],

    // --------------- Четвёртый блок -------------------

    rates: [
      {
        title: 'Low-cost',
        cost: '25 000',
        services: [
            'Анализируем ваш сайт',
            'Анализируем нишу',
            'Анализируем конкурентов',
            'Составляем маркетинговую стратегию',
            'Работаем над внутренними факторами'
        ]
      },
      {
        title: 'Standard',
        cost: 'от 40 000',
        services: [
          'Анализируем ваш сайт',
          'Анализируем нишу',
          'Анализируем конкурентов',
          'Составляем маркетинговую стратегию',
          'Работаем над внутренними и внешними факторами',
          'Проводим промежуточный анализ'
        ]
      },
      {
        title: 'Premium',
        cost: 'от 70 000',
        services: [
          'Анализируем ваш сайт',
          'Анализируем нишу',
          'Анализируем конкурентов',
          'Составляем маркетинговую стратегию',
          'Работаем над внутренними и внешними факторами',
          'Работаем над юзабилити сайта',
          'Создаем и оптимизируем контент',
          'Увеличиваем ссылочную массу сайта ЕСТЕСТВЕННЫМ путем',
          'Проводим промежуточный анализ после внесенных изменениях'
        ]
      }
    ]

  },

  /* smm: {
    description: {
      title: 'SMM',
      description: {
        first_paragraph: `Продвижение бренда не всегда ограничивается созданием информационной системы. Необходимо продвигать свои товары или услуги на различных прощадках, таких как социальные сети.`,
        second_paragraph: `За счёт грамотно построенной SMM-стратегии можно повысить узнаваемость бренда и заинтересовать большее количество людей вашим товаром или услугой, а также увеличить продажи и наладить коммуникацию с клиентами.`
      },
      start_price: 'от 10 000',
      tariffs: '3 тарифных плана'
    },


    important: {
      title: 'Почему SMM важен?',
      first_paragraph: `Благодаря социальным сетям вы сможете лучше понять интересы вашей аудитории, их мотивы и сформировать хорошее предложение. SMM стимулирует продажи, формирует лояльную к вашему бренду аудиторию, хорошо отрабатывает возражения и является отличным источником трафика.`,
      second_paragraph: `Продвижение в социальных сетях — это возможность понять ваших клиентов и хорошо заявить о себе.`
    },

    content: [
      {
        id: 0,
        title: 'Что входит в SMM?'
      },
      {
        id: 1,
        title: 'Аудит текущего состояния </br> социальных </br> сетей'
      },
      {
        id: 2,
        title: 'Создание стратегии ведение аккаунта на </br> 3 месяца'
      },
      {
        id: 3,
        title: 'Настройка доступов и создание </br> аккаунтов'
      },
      {
        id: 4,
        title: 'Оформление социальных </br> сетей'
      },
      {
        id: 5,
        title: 'Подготовка и публикация </br> контента'
      },
      {
        id: 6,
        title: 'Запуск&nbsp;таргетированной рекламы в </br> социальных </br> сетях'
      },
      {
        id: 7,
        title: 'Анализ результатов работы и корректировка </br> плана'
      },
      {
        id: 8,
        title: 'Создание отчетов по результатам </br> работы'
      },
      {
        id: 9,
        title: 'Анализ </br> конкурентов'
      },
      {
        id: 10,
        title: 'Анализ </br> целовой </br> аудитории'
      }
    ],

    rates: [
      {
        title: 'Low-cost',
        cost: '10 000',
        services: [
            'Анализируем целевую аудиторию',
            'Анализируем конкурентов',
            'Создаем контент-план для 1 соц.сети',
            'Готовим контент и публикуем его'
        ]
      },
      {
        title: 'Standard',
        cost: 'от 15 000',
        services: [
          'Определяем цель присутствия бренда в SN',
          'Проводим аудит социальных сетей',
          'Анализируем целевую аудиторию',
          'Анализируем конкурентов',
          'Создаем SMM-стратегию ведения 1 аккаунта',
          'Настраиваем доступы или создаем 1 аккаунт',
          'Оформляем 1 социальную сеть',
          'Готовим контент и публикуем его',
          'Настраиваем таргетированную рекламу',
          'Анализируем результаты работы'
        ]
      },
      {
        title: 'Premium',
        cost: 'от 20 000',
        services: [
          'Определяем цель присутствия бренда в SN',
          'Проводим аудит социальных сетей',
          'Анализируем ЦА и конкурентов',
          'Создаем SMM-стратегию ведения 2-х аккаунтов',
          'Настраиваем доступы или создаем 2 аккаунта',
          'Оформляем 2 социальные сети',
          'Готовим контент для 2-х соц.сетей и публикуем',
          'Настраиваем таргет на 2 соц.сети',
          'Анализируем результаты работы'
        ]
      }
    ]
  }, */

  context: {
    description: {
      title: 'Контекст',
      description: {
        first_paragraph: `Контекстная реклама - мощный инструмент в продвижении. Запустив контекст, вы охватите широкую аудиторию, быстро расскажете о бренде своей целевой аудитории и моментально получите трафик на вашу площадку.`,
        second_paragraph: `Контекст работает поэтапно: мы предлагаем сперва создать площадку, затем запускать рекламу.`
      },
      start_price: 'от 10 000',
      tariffs: '3 тарифных плана'
    },

    important: {
      title: 'Почему Контекст важен?',
      first_paragraph: `Контекст охватывает тех пользователей,  кто уже ищет рекламируемый продукт. Благрдаря контексту вы увеличите продажи, получите заявки или звонки от потенциальных клиентов, увеличите трафик на сайт или количество установок мобильного приложения, повысите узнаваемость бренда.`,
      second_paragraph: `Контекст — инструмент, который позволит быстро заявить о себе в интернете.`
    },

    content: [
      {
        id: 0,
        title: 'Что входит в контекстную рекламу?'
      },
      {
        id: 1,
        title: 'Медиапланирование'
      },
      {
        id: 2,
        title: 'Контекстная реклама в Яндекс'
      },
      {
        id: 3,
        title: 'Контекстная реклама в Google'
      },
      {
        id: 4,
        title: 'Сбор семантического ядра'
      }
    ],

    rates: [
      {
        title: 'Low-cost',
        cost: 'от 10 000',
        services: [
            'Анализ ниши и конкурентов',
            'Настройка целей в системах аналитики',
            'Настройка 1 или 2 рекламных кабинетов (Яндекс.Директ или Google Ads)',
            'Без последующего ведения'
        ]
      },
      {
        title: 'Standard',
        cost: '17 000',
        services: [
          'Анализ ниши и конкурентов',
          'Настройка целей в системах аналитики',
          'Настройка и ведение 1 рекламного кабинета (Яндекс.Директ или Google Ads)',
          'Контроль бюджетов',
          'Создание рекламных креативов',
          'Видеореклама',
          'Актуализация объявлений',
          'Корректировки ставок',
          'Корректировки площадок',
          'Оптимизация рекламных кампаний',
          'Масштабирование рекламных кампаний'
        ]
      },
      {
        title: 'Premium',
        cost: 'от 30 000',
        services: [
          'Анализ ниши и конкурентов',
          'Настройка целей в системах аналитики',
          'Настройка и ведение 2 рекламных кабинетов (Яндекс.Директ и Google Ads)',
          'Контроль бюджетов',
          'Создание рекламных креативов',
          'Видеореклама (в т.ч. реклама на YouTube)',
          'Актуализация объявлений',
          'Корректировки ставок',
          'Корректировки площадок',
          'Оптимизация рекламных кампаний',
          'Масштабирование рекламных кампаний'
        ]
      }
    ]
  },

  target: {
    description: {
      title: 'Таргетированная реклама',
      description: {
        first_paragraph: `Любому бизнесу хочется найти своих клиентов не прибегая к большим затратам. С ростом популярности социальных сетей появилась хорошая альтернатива контекстной рекламе — таргетированная. Ее основным плюсом является то, что таргет можно настроить без наличия сайта компании.`,
        second_paragraph: `В России самыми популярными социальными сетями для настройки таргета явяются ВК, Одноклассники  и так далее.`
      },
      start_price: 'от 10 000',
      tariffs: '2 тарифных плана'
    },

    important: {
      title: 'Почему таргетированная реклама важна?',
      first_paragraph: `С помощью таргетированной рекламы можно рекламировать свой товар или услугу, не имея сайта. Можно просто создать страницу в социальной сети и начать привлекать потенциальных клиентов.\nТаргетированная реклама предоставляет широкие настройки объявлений, чтобы лучше всего попасть в вашу целевую аудиторию. С ее помощью вы сможете повысить объемы продаж, оптимизировать рекламный бюджет, а также повысить узнаваемость бренда в социальных сетях.`,
      second_paragraph: ``
    },
    content: [
      {
        id: 0,
        title: 'Что входит в таргет?'
      },
      {
        id: 1,
        title: 'Настройка таргетированной рекламы <br>в соц.сетях'
      },
      {
        id: 2,
        title: 'Анализ конкурентов'
      },
      {
        id: 3,
        title: 'Анализ целевой аулитории'
      },
      {
        id: 4,
        title: 'Создание рекламных креативов'
      },
      {
        id: 5,
        title: 'Анализ результатов работы'
      },
      {
        id: 6,
        title: 'Аудит социальных сетей'
      },
      {
        id: 7,
        title: 'Создание рекламных кабинетов'
      },
      {
        id: 8,
        title: 'Помощь в пополнении рекламного <br>кабинета'
      },
    ],

    rates: [
      {
        title: 'Low-cost',
        cost: '10 000',
        services: [
          'Проводим аудит 1 соц.сети',
          'Анализируем конкурентов',
          'Анализируем целевую аудиторию',
          'Создаем рекламные креативы',
          'Настраиваем таргет в 1 соц.сети',
          'Анализируем результаты'
        ]
      },
      {
        title: 'Standard',
        cost: 'от 15 000',
        services: [
          'Проводим аудит соц.сети',
          'Анализируем конкурентов',
          'Анализируем целевую аудиторию',
          'Создаем рекламные креативы',
          'Настраиваем таргет в 2 соц.сетях',
          'Анализируем результаты'
        ]
      },
    ]

  },

  landing: {
    description: {
      title: 'Лендинг',
      description: {
        first_paragraph: `Лендинг - веб-страница, которая призывает пользователя совершить одно целевое действие. Он пригодится для продажи конкретного товара, сбора лидов, презентации событий или тестировании нового продукта. `,
        second_paragraph: `Лендинг полезен всегда, когда предложение можно поместить на кнопку. Классический сценарий лендинга - зашёл, понял, сделал. За нами же задача сделать так, чтобы сценарий сработал.`
      },
      start_price: 'от 10 000',
      tariffs: '1 тарифный план'
    },
    important: {
      title: 'Почему лендинги важны?',
      first_paragraph: `Лендинги - это один из инструментов продвижения бизнеса. Посадочная страница, как часть рекламной кампании, концентрирует внимание пользователя на совершении конверсионного действия.`,
      second_paragraph: `Лендинги универсальны, их можно использовать:
      когда основной сайт уже есть,
      когда основного сайт ещё нет,
      когда только начинаешь бизнес.`
    },
    content: [
      {
        id: 0,
        title: 'Что входит в <br> Лендинг?'
      },
      {
        id: 1,
        title: 'Разработка <br> технического <br> задания'
      },
      {
        id: 2,
        title: 'Разработка <br> дизайн-макетов'
      },
      {
        id: 3,
        title: 'Согласование <br> дизайн-макетов'
      },
      {
        id: 4,
        title: 'Вёрстка <br> дизайн-<br> макетов'
      },
      {
        id: 5,
        title: 'Возможность <br>  внесения <br>  правок <br>  заказчиком'
      },
      {
        id: 6,
        title: 'Передача <br> исходного <br> кода'
      }
    ],

    rates: [
      {
        title: 'Стандарт',
        cost: '10 000',
        services: [
            'Разработка технического задания проекта',
            'Разработка дизайна под желания заказчика',
            'Согласование дизайн-макетов: 2 правки',
            'Вёрстка дизайн-макетов',
            'Загрузка сайта на сервер заказчика',
            'Передача исходного кода'
        ]
      }
    ]
  },

  multipage: {
    description: {
      title: 'Многостраничный сайт',
      description: {
        first_paragraph: `Многостраничный сайт – классный инструмент взаимодействия компании с клиентами. Такой сайт является полноценной презентацией бренда, ваших товаров и услуг.`,
        second_paragraph: `Содержание многостраничного сайта не ограничено – количество страниц зависит только от объема информации и количества продуктов компании, с которыми требуется познакомить пользователей.`
      },
      start_price: 'от 10 000',
      tariffs: '1 тарифный план'
    },
    important: {
      title: 'Почему многостраничные сайты важны?',
      first_paragraph: `Наличие многостраничного сайта делает компанию на ступеньку выше. Информация на сайте познакомит пользователей с брендом и удержит внимание  потенциального клиента на вашем ресурсе.`,
      second_paragraph: `Здесь могут размещаться многочисленные карточки товаров, страницы услуг, служебные страницы, отзывы или блог. Преимуществом многостраничного сайта является возможность охватить широкий диапазон аудитории.`
    },
    content: [
      {
        id: 0,
        title: 'Что входит в <br> многостраничный <br> сайт?'
      },
      {
        id: 1,
        title: 'Разработка <br> технического <br> задания'
      },
      {
        id: 2,
        title: 'Разработка <br> дизайн-макетов'
      },
      {
        id: 3,
        title: 'Согласование <br> дизайн-макетов'
      },
      {
        id: 4,
        title: 'Возможность <br> внесения <br> правок <br> заказчиком'
      },
      {
        id: 5,
        title: 'Вёрстка <br> дизайн-<br> макетов'
      },
      {
        id: 6,
        title: 'Разработка <br> бизнес-<br>логики <br> системы'
      },
      {
        id: 7,
        title: 'Передача <br> исходного <br> кода'
      }
    ],

    rates: [
      {
        title: 'Стандарт',
        cost: '10 000',
        services: [
            'Разработка технического задания проекта',
            'Разработка дизайна под желания заказчика',
            'Согласование дизайн-макетов: 2 правки',
            'Вёрстка дизайн-макетов',
            'Загрузка сайта на сервер заказчика',
            'Передача исходного кода'
        ]
      }
    ]
  },

  shop: {
    description: {
      title: 'Интернет-магазин',
      description: {
        first_paragraph: `Интернет-магазин позволяет покупателям найти, выбрать и приобрести товар находясь в любой точке мира. Готовая система является полноценным магазином с каталогом, оформлением заказов и информацией о компании.`,
        second_paragraph: `Интернет-магазин станет хорошим решением как для крупного, так и для малого бизнеса.`
      },
      start_price: 'от 10 000',
      tariffs: '1 тарифный план'
    },
    important: {
      title: 'Почему интернет-магазины важны?',
      first_paragraph: `Наличие интернет-магазина позволяет охватить больше потенциальных клиентов, а следовательно, повысить продажи и узнаваемость бренда.`,
      second_paragraph: `Покупатель сможет совершать покупки в любое время суток независимо от своего местоположения. А фунциональный интернет-магазин станет не только средством увеличения заработка, но и настоящим презентационным ресурсом компании.`
    },
    content: [
      {
        id: 0,
        title: 'Что входит в <br> интернет-<br>магазин?'
      },
      {
        id: 1,
        title: 'Разработка <br> технического <br> задания'
      },
      {
        id: 2,
        title: 'Разработка <br> дизайн-макетов'
      },
      {
        id: 3,
        title: 'Согласование <br> дизайн-макетов'
      },
      {
        id: 4,
        title: 'Возможность <br> внесения <br> правок <br> заказчиком'
      },
      {
        id: 5,
        title: 'Вёрстка <br> дизайн-<br> макетов'
      },
      {
        id: 6,
        title: 'Разработка <br> бизнес-<br>логики <br> системы'
      },
      {
        id: 7,
        title: 'Передача <br> исходного <br> кода'
      }
    ],

    rates: [
      {
        title: 'Стандарт',
        cost: '10 000',
        services: [
            'Разработка технического задания проекта',
            'Разработка дизайна под желания заказчика',
            'Согласование дизайн-макетов: 2 правки',
            'Вёрстка дизайн-макетов',
            'Загрузка сайта на сервер заказчика',
            'Передача исходного кода'
        ]
      }
    ]
  },

  custom: {
    description: {
      title: 'Индивидуальное решение',
      description: {
        first_paragraph: `Не можете найти готовое решение для своего бизнеса? Наша команда готова разработать индивидуальное IT-решение для автоматизации потребностей и бизнес-процессов вашей компании.`,
        second_paragraph: ``
      },
      start_price: 'от 10 000',
      tariffs: '1 тарифный план'
    },
    important: {
      title: 'Почему индивидуальное решение важно?',
      first_paragraph: `Индивидуальное решение позволяет сосредоточиться на потребностях бизнеса и вывести ваш продукт на новую ступень, что позволит повысить его конкурентоспособность на рынке.`,
      second_paragraph: `Готовое ПО можно интегрировать с уже существующими сервисами компании, а также модернизировать системы и приложения с целью оптимизации бизнес-процессов бренда. В итоге мы получаем современное и экономически выгодное решение.`
    },
    content: [
      {
        id: 0,
        title: 'Что входит в <br> разработку <br> индивидуального <br> решения?'
      },
      {
        id: 1,
        title: 'Разработка <br> технического <br> задания'
      },
      {
        id: 2,
        title: 'Разработка <br> дизайн-макетов'
      },
      {
        id: 3,
        title: 'Согласование <br> дизайн-макетов'
      },
      {
        id: 4,
        title: 'Возможность <br> внесения <br> правок <br> заказчиком'
      },
      {
        id: 5,
        title: 'Вёрстка <br> дизайн-<br> макетов'
      },
      {
        id: 6,
        title: 'Разработка <br> бизнес-<br>логики <br> системы'
      },
      {
        id: 7,
        title: 'Передача <br> исходного <br> кода'
      }
    ],

    rates: [
      {
        title: 'Стандарт',
        cost: '10 000',
        services: [
          'Разработка технического задания проекта',
          'Разработка дизайна под желания заказчика',
          'Согласование дизайн-макетов: 2 правки',
          'Вёрстка дизайн-макетов',
          'Загрузка сайта на сервер заказчика',
          'Передача исходного кода'
        ]
      }
    ]
  },

  CMS: {
    description: {
      title: 'Разработка на CMS',
      description: {
        first_paragraph: `CMS – система управления содержимым, которая позволяет создать любой продукт от лендинга до интернет-магазина быстрее, в отличие от рукописного кода. Кроме разработки, CMS упрощает и дальнейшее администрирование и продвижение созданного продукта.`,
        second_paragraph: `Если вам нужен готовый продукт в короткие сроки, то разработка на CMS ваш выбор.`
      },
      start_price: 'от 10 000',
      tariffs: '1 тарифный план'
    },
    important: {
      title: 'Почему CMS важны?',
      first_paragraph: `CMS является простым решением для типовых продуктов, так как содержит в себе готовые модули личного кабинета, блога, интернет-магазина и т.д. Использование этих модулей позволяет ускорить не только разработку продукта, но и заполнение его контентом.`,
      second_paragraph: `Дальнейшее администрирование и продвижение созданного продукта производится через административную панель самой CMS, с которой справится даже не профессионал.`
    },
    content: [
      {
        id: 0,
        title: 'Что входит в <br> разработку на <br> CMS?'
      },
      {
        id: 1,
        title: 'Разработка <br> технического <br> задания'
      },
      {
        id: 2,
        title: 'Разработка <br> дизайн-макетов'
      },
      {
        id: 3,
        title: 'Согласование <br> дизайн-макетов'
      },
      {
        id: 4,
        title: 'Возможность <br> внесения <br> правок <br> заказчиком'
      },
      {
        id: 5,
        title: 'Подбор CMS'
      },
      {
        id: 6,
        title: 'Вёрстка <br> дизайн-<br> макетов'
      },
      {
        id: 7,
        title: 'Разработка <br> бизнес-<br>логики <br> системы'
      },
      {
        id: 8,
        title: 'Передача <br> доступов к cms <br> и всем <br> необходимым <br> сервисам'
      }
    ],

    rates: [
      {
        title: 'Лендинг',
        cost: '10 000',
        services: [
          'Разработка технического задания проекта',
          'Разработка дизайна под желания заказчика',
          'Согласование дизайн-макетов: 2 правки',
          'Вёрстка дизайн-макетов',
          'Загрузка сайта на сервер заказчика',
          'Передача исходного кода'
        ]
      },
      {
        title: 'Многостраничный сайт',
        cost: '10 000',
        services: [
          'Разработка технического задания проекта',
          'Разработка дизайна под желания заказчика',
          'Согласование дизайн-макетов: 2 правки',
          'Вёрстка дизайн-макетов',
          'Загрузка сайта на сервер заказчика',
          'Передача исходного кода'
        ]
      }
    ]
  },

  support: {
    description: {
      title: 'Поддержка систем и приложений',
      description: {
        first_paragraph: `Поддержка систем и приложений -- важный этап для владельцев информационных систем. Он необходим для добавления новой информации, страниц, модулей, подключения плагинов и так далее.`,
        second_paragraph: `Работа сайта может приостановиться или нарушиться из-за ошибок и уязвимостей. Поддержка подразумевает контроль за работой ресурса и оперативное устранение ошибок.`
      },
      start_price: 'от 5 000',
      tariffs: '1 тарифный план'
    },
    important: {
      title: 'Почему поддержка систем и приложений важна?',
      first_paragraph: `Благодаря поддержке можно не беспокоиться за то, что сайт перестанет работать или обновляться длительный промежуток времени. Любая система представляем из себя сложный механизм, который нужно контролировать.`,
      second_paragraph: `Любой ресурс требует актуализации информации, добавления новых функций, фишек и устранения ошибок. Только благодаря поддержке сайт сможет оставаться в рабочем состоянии и продолжать приносить доход.`
    },
    content: [
      {
        id: 0,
        title: 'Что входит в <br> поддержку <br> систем и <br> приложений?'
      },
      {
        id: 1,
        title: 'Обновление <br> информации'
      },
      {
        id: 2,
        title: 'Исправление ошибок'
      },
      {
        id: 3,
        title: 'Подключение сервисов'
      },
      {
        id: 4,
        title: 'Усовершенствование <br> функционала'
      },
      {
        id: 5,
        title: 'Замена плагинов <br> и модулей'
      },
      {
        id: 6,
        title: 'Запуск таргентированной <br> рекламы <br> в социальных <br> сетях'
      },
    ],

    rates: [
      {
        title: 'Стандарт',
        cost: '10 000',
        services: [
          'Разработка технического задания проекта',
          'Разработка в рамках технического задания',
          'Тестирование и отладка'
        ]
      }
    ]
  },

  cross_platform: {
    description: {
      title: 'Разработка кроссплатформенного приложения на языке программирования Flutter',
      description: {
        first_paragraph: `Многие проекты сейчас нуждаются уже не только в сайтах и информационных системах, для их существования также необходимы мобильные приложения.  Они помогают пользователям более удобный способ взаимодействия с вашим продуктом.`,
        second_paragraph: ``
      },
      start_price: 'от 100 000',
      tariffs: '3 тарифных плана'
    },
    important: {
      title: 'Почему мобильные приложения важны?',
      first_paragraph: `Смартфоны сейчас есть у большинства людей и именно при помощи них люди теперь получают доступ к информации и пользуются различными услугами. Мобильное приложение самый удобный способ взаимодействия с пользователями, так как оно максимально органично существуют в смартфоне и может использовать максимальное количество его функицй и опций.`,
      second_paragraph: ``
    },
    content: [
      {
        id: 0,
        title: 'Что входит в <br> разработку <br> приложения?'
      },
      {
        id: 1,
        title: 'Оценка макетов и <br> сложности <br> разработки'
      },
      {
        id: 2,
        title: 'Разработка <br> архитектуры'
      },
      {
        id: 3,
        title: 'Проектирование <br> экранов <br> приложения'
      },
      {
        id: 4,
        title: 'Тестирование <br> готового <br> продукта'
      },
      {
        id: 5,
        title: 'Размещение <br> приложения в <br> соответствующем <br> маркете'
      },
      {
        id: 6,
        title: 'Передача исходного <br> кода'
      },
      {
        id: 7,
        title: 'Разработка <br> бизнес-<br>логики <br> системы'
      },
      {
        id: 8,
        title: 'Передача <br> доступов к cms и всем <br> необходимым сервисам'
      }
    ],

    rates: [
      {
        title: 'Light',
        cost: 'от 100 000',
        services: [
          'Разработка ведётся одним Junior-разработчиком',
          'Оценка макетов, сложности разработки',
          'Разработка архитектуры приложения',
          'Создание экранов приложения',
          'Связь с сервером',
          'Тестирование',
          'Выгрузка приложения в маркет'
        ]
      },
      {
        title: 'Standard',
        cost: 'от 100 000',
        services: [
          'Разработка ведётся двумя Junior-разработчиками',
          'Оценка макетов, сложности разработки',
          'Разработка архитектуры приложения',
          'Создание экранов приложения',
          'Связь с сервером',
          'Тестирование',
          'Выгрузка приложения в маркет'
        ]
      },
      {
        title: 'VIP',
        cost: '100 000',
        services: [
          'Разработка ведётся двумя Middle-разработчиками',
          'Оценка макетов, сложности разработки',
          'Разработка архитектуры приложения',
          'Создание экранов приложения',
          'Связь с сервером',
          'Тестирование',
          'Выгрузка приложения в маркет'
        ]
      }
    ]
  },

  iOS: {
    description: {
      title: 'Разработка iOS приложения на языке программирования Swift',
      description: {
        first_paragraph: `Многие проекты сейчас нуждаются уже не только в сайтах и информационных системах, для их существования также необходимы мобильные приложения.  Они помогают пользователям более удобный способ взаимодействия с вашим продуктом.`,
        second_paragraph: ``
      },
      start_price: 'от 100 000',
      tariffs: '3 тарифных плана'
    },

    important: {
      title: 'Почему мобильные приложения важны?',
      first_paragraph: `Смартфоны сейчас есть у большинства людей и именно при помощи них люди теперь получают доступ к информации и пользуются различными услугами. Мобильное приложение самый удобный способ взаимодействия с пользователями, так как оно максимально органично существуют в смартфоне и может использовать максимальное количество его функицй и опций.`,
      second_paragraph: ``
    },

    content: [
      {
        id: 0,
        title: 'Что входит в <br> разработку <br> приложения?'
      },
      {
        id: 1,
        title: 'Оценка макетов и <br> сложности <br> разработки'
      },
      {
        id: 2,
        title: 'Разработка <br> архитектуры'
      },
      {
        id: 3,
        title: 'Проектирование <br> экранов <br> приложения'
      },
      {
        id: 4,
        title: 'Тестирование <br> готового <br> продукта'
      },
      {
        id: 5,
        title: 'Размещение <br> приложения в <br> соответствующем <br> маркете'
      },
      {
        id: 6,
        title: 'Передача исходного <br> кода'
      },
    ],

    rates: [
      {
        title: 'Light',
        cost: 'от 100 000',
        services: [
          'Разработка ведётся одним Junior-разработчиком',
          'Оценка макетов, сложности разработки',
          'Разработка архитектуры приложения',
          'Создание экранов приложения',
          'Связь с сервером',
          'Тестирование',
          'Выгрузка приложения в маркет'
        ]
      },
      {
        title: 'Standard',
        cost: 'от 100 000',
        services: [
          'Разработка ведётся двумя Junior-разработчиками',
          'Оценка макетов, сложности разработки',
          'Разработка архитектуры приложения',
          'Создание экранов приложения',
          'Связь с сервером',
          'Тестирование',
          'Выгрузка приложения в маркет'
        ]
      },
      {
        title: 'VIP',
        cost: '100 000',
        services: [
          'Разработка ведётся двумя Middle-разработчиками',
          'Оценка макетов, сложности разработки',
          'Разработка архитектуры приложения',
          'Создание экранов приложения',
          'Связь с сервером',
          'Тестирование',
          'Выгрузка приложения в маркет'
        ]
      }
    ]
  },

  android: {
    description: {
      title: 'Разработка Android приложения на языке программирования Kotlin',
      description: {
        first_paragraph: `Многие проекты сейчас нуждаются уже не только в сайтах и информационных системах, для их существования также необходимы мобильные приложения.  Они помогают пользователям более удобный способ взаимодействия с вашим продуктом.`,
        second_paragraph: ``
      },
      start_price: 'от 100 000',
      tariffs: '3 тарифных плана'
    },

    important: {
      title: 'Почему мобильные приложения важны?',
      first_paragraph: `Смартфоны сейчас есть у большинства людей и именно при помощи них люди теперь получают доступ к информации и пользуются различными услугами. Мобильное приложение самый удобный способ взаимодействия с пользователями, так как оно максимально органично существуют в смартфоне и может использовать максимальное количество его функицй и опций.`,
      second_paragraph: ``
    },

    content: [
      {
        id: 0,
        title: 'Что входит в <br> разработку <br> приложения?'
      },
      {
        id: 1,
        title: 'Оценка макетов и <br> сложности <br> разработки'
      },
      {
        id: 2,
        title: 'Разработка <br> архитектуры'
      },
      {
        id: 3,
        title: 'Проектирование <br> экранов <br> приложения'
      },
      {
        id: 4,
        title: 'Тестирование <br> готового <br> продукта'
      },
      {
        id: 5,
        title: 'Размещение <br> приложения в <br> соответствующем <br> маркете'
      },
      {
        id: 6,
        title: 'Передача исходного <br> кода'
      },
    ],

    rates: [
      {
        title: 'Light',
        cost: 'от 100 000',
        services: [
          'Разработка ведётся одним Junior-разработчиком',
          'Оценка макетов, сложности разработки',
          'Разработка архитектуры приложения',
          'Создание экранов приложения',
          'Связь с сервером',
          'Тестирование',
          'Выгрузка приложения в маркет'
        ]
      },
      {
        title: 'Standard',
        cost: 'от 100 000',
        services: [
          'Разработка ведётся двумя Junior-разработчиками',
          'Оценка макетов, сложности разработки',
          'Разработка архитектуры приложения',
          'Создание экранов приложения',
          'Связь с сервером',
          'Тестирование',
          'Выгрузка приложения в маркет'
        ]
      },
      {
        title: 'VIP',
        cost: '100 000',
        services: [
          'Разработка ведётся двумя Middle-разработчиками',
          'Оценка макетов, сложности разработки',
          'Разработка архитектуры приложения',
          'Создание экранов приложения',
          'Связь с сервером',
          'Тестирование',
          'Выгрузка приложения в маркет'
        ]
      }
    ]
  },

  landing_dis: {
    description: {
      title: 'Дизайн лендинга',
      description: {
        first_paragraph: `Лендинг — это одностраничный сайт, задачей которого является совершение посетителем целевого действия. Как правило, таким действием является покупка продукции или услуги.`,
        second_paragraph: `Лендинг очень уникальный формат: на одностраничном сайте внимание пользователя фокусируется исключительно на продвигаемом товаре, услуге или персоне, он подходит для проведения рекламных акций и других ивентов.`
      },
      start_price: 'от 10 000',
      tariffs: '2 тарифных плана'
    },

    important: {
      title: 'Почему дизайн лендинга важен?',
      first_paragraph: `Лендинги цепляют в первую очередь своей визуальной составляющей и характером. Помимо эстетики и узнаваемости правильный дизайн способен привлекать и направлять взгляд пользователя, подталкивая его к совершению целевого действия.`,
      second_paragraph: ``
    },

    content: [
      {
        id: 0,
        title: 'Что входит в лендинг?'
      },
      {
        id: 1,
        title: 'Проработка <br> требований'
      },
      {
        id: 2,
        title: 'Анализ <br> конкурентов'
      },
      {
        id: 3,
        title: 'Концепция <br> и наброски <br> страницы'
      },
      {
        id: 4,
        title: 'Внедрение или <br> проработка <br> визуального <br> стиля'
      },
      {
        id: 5,
        title: 'Проработка <br> уникальной <br> графики, <br> анимаций'
      },
      {
        id: 6,
        title: 'Внедрение <br> контента (текста, <br> фотографии)'
      },
      {
        id: 7,
        title: 'Проработка <br> адаптива (вид <br> макета на разных <br> устройствах)'
      },
      {
        id: 8,
        title: 'Передача <br> разработчикам'
      }
    ],

    rates: [
      {
        title: 'Standart',
        cost: 'от 10 000',
        services: [
            'Анализ конкурентов',
            'Создание до 6 уникальных блоков',
            'Внедрение визуального стиля',
            'До 3 ручных иллюстраций',
            'Проработка адаптива',
            'Передача в разработку'
        ]
      },
      {
        title: 'Premium',
        cost: 'от 20 000',
        services: [
            'Анализ конкурентов',
            'Создание до 10 уникальных блоков',
            'Проектирование логики для дополнительных форм и продуктовых каталогов',
            'Проработка визуального стиля',
            'До 5 ручных иллюстраций',
            'Дизайн анимаций',
            'Проработка адаптива',
            'Передача в разработку'
        ]
      }
    ]
  },

  multipage_dis: {

    description: {
      title: 'Дизайн сайта',
      description: {
        first_paragraph: `Современный бренд тяжело представить без представительства в сети. Это может быть или простой информационный сайт в несколько страниц или большой хорошо продуманный сервис или интернет-магазин.`,
        second_paragraph: `Мы способны спроектировать сайт любой сложности, учитывая бизнес-цели, предпочтения пользователей и ограничения разработки. Вы получите современный, продуманный дизайн, простой в использовании и расширении.`
      },
      start_price: 'от 20 000',
      tariffs: '3 тарифных плана'
    },

    important: {
      title: 'Почему дизайн сайта важен?',
      first_paragraph: `Дизайнер в разработке сайтов является тем, кто объединяет интересы бизнеса, пользователей и разработчиков. В результате продукт получается реализуемым, масштабируемым, удобным и приносящим прибыль. Дизайн отвечает как за красоту, так и за логичность расположения информации/совершения действий.`,
      second_paragraph: ``
    },
    content: [
      {
        id: 0,
        title: 'Что входит в дизайн сайта?'
      },
      {
        id: 1,
        title: 'Проработка <br> требований'
      },
      {
        id: 2,
        title: 'Анализ конкурентов <br> и пользовательских <br> сценариев'
      },
      {
        id: 3,
        title: 'Структура и <br> навигация сайта'
      },
      {
        id: 4,
        title: 'Вайрфреймы <br> (наброски, <br> концепция)'
      },
      {
        id: 5,
        title: 'Внедрение или <br> разработка <br> визуального <br> стиля'
      },
      {
        id: 6,
        title: 'Создание <br> системы <br> компонентов'
      },
      {
        id: 7,
        title: 'Создание <br> окончательных <br> дизайн-макетов'
      },
      {
        id: 8,
        title: 'Проработка <br> взаимодействий'
      },
      {
        id: 9,
        title: 'Проработка <br> адаптива (вид <br> макета на разных <br> устройствах)'
      },
      {
        id: 10,
        title: 'Передача, <br> поддержка <br> в ходе <br> разработки'
      }
    ],

    rates: [
      {
        title: 'Light',
        cost: 'от 20 000',
        services: [
            'До 4 уникальных страниц',
            'Небольшой сайт без сложных функций',
            'Адаптация готового фирменного стиля ',
            'Разовая передача разработчикам'
        ]
      },
      {
        title: 'Standart',
        cost: 'от 35 000',
        services: [
            'До 10 уникальных страниц',
            'Информационный сайт с функциями',
            'Согласование на каждом этапе',
            'Проработка и расширение стиля',
            'Поддержка в ходе разработки'
        ]
      },
      {
        title: 'Premium',
        cost: 'от 50 000',
        services: [
            'До 20 уникальных страниц',
            'Магазины и WEB-приложения',
            'Согласование на каждом этапе',
            'Проработка визуального стиля',
            'Ведение внутренней документации',
            'Работа с анимациями',
            'Тестирование решений на прототипе',
            'Поддержка в ходе разработки'
        ]
      }
    ]
  },

  mobile: {

    description: {
      title: 'Дизайн мобильного приложения',
      description: {
        first_paragraph: `Если решать повседневные задачи онлайн, то только в мобильных приложениях! Не важно, создаете вы приложение для бизнеса или начинаете стартап, грамотное проектирование - первый шаг к успеху.`,
        second_paragraph: `Мы способны спроектировать приложение любой сложности для Android или iOS, учитывая бизнес-цели, предпочтения пользователей и ограничения разработки. Вы получите современный, продуманный дизайн, простой в использовании и расширении.`
      },
      start_price: 'от 15 000',
      tariffs: '3 тарифных плана'
    },

    important: {
      title: 'Почему дизайн приложений важен?',
      first_paragraph: `Дизайн объединяет бизнес, пользователей и разработчиков. В результате продукт получается реализуемым, масштабируемым, удобным и приносящим прибыль. Дизайн отвечает как за красоту, так и за логичность расположения информации и совершения действий. Для мобильных приложений важно правильное применение жестов, компактная, но понятная навигация.`,
      second_paragraph: ``
    },

    content: [
      {
        id: 0,
        title: 'Что входит в дизайн?'
      },
      {
        id: 1,
        title: 'Проработка <br> требований'
      },
      {
        id: 2,
        title: 'Анализ конкурентов и <br>  пользовательских <br> сценариев'
      },
      {
        id: 3,
        title: 'Структура <br> приложения'
      },
      {
        id: 4,
        title: 'Навигационная <br> концепция'
      },
      {
        id: 5,
        title: 'Вайрфреймы <br> (наброски экранов)'
      },
      {
        id: 6,
        title: 'Внедрение или <br> разработка <br> визуального <br> стиля'
      },
      {
        id: 7,
        title: 'Создание системы <br> компонентов (под <br> платформы)'
      },
      {
        id: 8,
        title: 'Создание <br> окончательных <br> дизайн-макетов <br> для одной <br> платформы'
      },
      {
        id: 9,
        title: 'Проработка <br> взаимодействий'
      },
      {
        id: 10,
        title: 'Перенос <br> дизайн-макетов <br> для второй <br> платформы'
      },
      {
        id: 11,
        title: 'Передача, <br> поддержка <br> в ходе <br> разработки'
      }
    ],

    rates: [
      {
        title: 'Light',
        cost: 'от 15 000',
        services: [
            'До 4 уникальных страниц',
            'Простая структура',
            'Использование стандартных компонентов',
            'Одна платформа (+ 5 000 RUB за вторую)',
            'Адаптация готового фирменного стиля ',
            'Разовая передача разработчикам'
        ]
      },
      {
        title: 'Standart',
        cost: 'от 40 000',
        services: [
            'До 8 уникальных страниц',
            'Несложные взаимодействия',
            'Согласование на каждом этапе',
            'Свой дизайн с учетом нативных элементов',
            'Две платформы (iOS и Android)',
            'Проработка и расширение стиля',
            'Поддержка в ходе разработки'
        ]
      },
      {
        title: 'Premium',
        cost: 'от 80 000',
        services: [
            'Сложное приложение от 8 страниц',
            'Множество взаимодействий (например, финансовые или социальные)',
            'Согласование на каждом этапе',
            'Свой дизайн с учетом нативных элементов',
            'Две платформы (iOS и Android)',
            'Ведение внутренней документации',
            'Проработка визуального стиля',
            'Тестирование решений на прототипе',
            'Поддержка в ходе разработки'
        ]
      }
    ]
  },

  brand: {

    description: {
      title: 'Брендинг',
      description: {
        first_paragraph: `Бренд компании - это не просто ее логотип. Благодаря айдентике мы можем построить целый язык визуальной коммуникации, который задает настроение, отношение и даже чувства компании по отношени к своим клиентам.`,
        second_paragraph: `Мы создадим знак, который будет нести в себе нужный посыл и подойдет для различных применений, и расширим этот посыл в подробном брендбуке.`
      },
      start_price: 'от 3 000',
      tariffs: '3 тарифных плана'
    },

    important: {
      title: 'Почему брендинг важен?',
      first_paragraph: `Благодаря грамотному подходу можно сформировать нужное восприятие продукции или услуги. Бренд обеспечивает привлечение и удержание клиентов, сотрудников, инвесторов, что дает основу для долгосрочного роста организации, ее узнаваемости и доверия окружающих. `,
      second_paragraph: ``
    },

    content: [
      {
        id: 0,
        title: 'Что входит в брендинг?'
      },
      {
        id: 1,
        title: 'Сбор требований, <br> пожеланий'
      },
      {
        id: 2,
        title: 'Анализ конкурентов <br> и клиентских <br> ожиданий '
      },
      {
        id: 3,
        title: 'Проработка концепции, <br> позиционирования'
      },
      {
        id: 4,
        title: 'Подбор цветов, <br> шрифтов, иконок'
      },
      {
        id: 5,
        title: 'Разработка логотипа <br> с промежуточным <br> согласованием'
      },
      {
        id: 6,
        title: 'Примеры <br> адаптации <br> логотипа для <br> разных <br> форматов'
      },
      {
        id: 7,
        title: 'Элементы <br> графического <br> оформления'
      },
      {
        id: 8,
        title: 'Правила создания <br> и применения <br> графики: <br> брендбук'
      },
      {
        id: 9,
        title: 'Стратегия по <br> расширению <br> бренда'
      }
    ],

    rates: [
      {
        title: 'Light',
        cost: 'от 5 000',
        services: [
            'Выбор из 3 проработанных вариантов ',
            'Основные правила оформления',
            '3-4 примера применения'
        ]
      },
      {
        title: 'Standart',
        cost: 'от 8 000',
        services: [
            'Выбор из 10 набросков ',
            'Выбор из 3 проработанных вариантов  ',
            'Варианты адаптации логотипа',
            'Проработка фирменного стиля ',
            '4-7 примера применения'
        ]
      },
      {
        title: 'Premium',
        cost: 'от 20 000',
        services: [
            'Проработка концепции бренда',
            'Выбор из 10 набросков',
            'Выбор из 3 проработанных вариантов',
            'Варианты и правила адаптации логотипа',
            'Фирменный стиль на основе концепции',
            'Подробный брендбук',
            'Правила применения с примерами'
        ]
      }
    ]
  },

  social_media: {

    description: {
      title: 'Дизайн социальных сетей',
      description: {
        first_paragraph: `Мы помогаем придать вашим профилям в социальных сетях опрятный, узнаваемый внешний вид, сделать посты уникальными, а также учим использовать готовые шаблоны самостоятельно, без помощи дизайнера.`,
        second_paragraph: ``
      },
      start_price: 'от 3 000',
      tariffs: '3 тарифных плана'
    },

    important: {
      title: 'Почему дизайн для соц.сетей важен?',
      first_paragraph: `Без привлечения профессионала оформление профиля в социальной сети может выглядеть “грязно” и разрозненно. Дизайнер подходит к созданию картинок комплексно, формируя общий визуальный стиль и сосредотачиваясь на подаче контента.`,
      second_paragraph: ``
    },

    content: [
      {
        id: 0,
        title: 'Что входит в дизайн?'
      },
      {
        id: 1,
        title: 'Сбор требований, <br> пожеланий'
      },
      {
        id: 2,
        title: 'Анализ конкурентов <br> и клиентских <br> ожиданий '
      },
      {
        id: 3,
        title: 'Проработка концепции, <br> позиционирования'
      },
      {
        id: 4,
        title: 'Подбор элементов <br> оформления на основе <br> вашего брендинга'
      },
      {
        id: 5,
        title: 'Создание <br> системы <br> шаблонов <br> в Figma'
      },
      {
        id: 6,
        title: 'Согласование <br> полоного <br> оформления'
      },
      {
        id: 7,
        title: 'Передача шаблонов <br> и обучение по их <br> использованию'
      }
    ],

    rates: [
      {
        title: 'Light',
        cost: 'от 3 000',
        services: [
            'Полное оформление Instagram (аватарка, шаблоны сторис и постов, актуальное)',
            'На основе готового брендинга',
            'Шаблоны для дальнейшего использования'
        ]
      },
      {
        title: 'Standart',
        cost: 'от 4 000',
        services: [
            'Полное оформление ВКонтакте (шапка, аватарка, кнопки меню, шаблоны сторис и постов, обложки альбомов)',
            'На основе готового брендинга',
            'Шаблоны для дальнейшего использования'
        ]
      },
      {
        title: 'Premium',
        cost: 'от 15 000',
        services: [
            'Комплексное оформление любых двух социальных сетей',
            'Дополнение готового брендинга',
            'Система в Figma для простого использования шаблонов',
            'Правила для создания новых шаблонов'
        ]
      }
    ]
  },
}
